<template>
	<div v-if="selectedAvatar" class="videocall-container d-flex justify-center align-center" :style="{ height: dynamicHeight + 'px' }">
		<v-btn v-if="!stream"
			class="back-button"
			@click="selectedAvatar = null">
			<v-icon large>
				mdi-chevron-left
			</v-icon>
			<div>Volver</div>
		</v-btn>

		<div v-if="stream"  style="width: 100%; position:relative;">

		<video
			class="remote-stream"
			:class="[selectedAvatar.avatar_id == '8facbf5854194f348d3956586fc584ae' ? 'remote-stream-vertical' : 'remote-stream']"
			:srcObject.prop="stream"
			autoPlay
			playsInline
			style="width: 100%;max-height: 100vh;">
			<track kind="captions" />
		</video>
		<video
		class="local-stream" ref="video" autoplay muted style="object-fit: cover;"></video>

		</div>
		

		<div class="loading-text" v-else-if="isLoadingSession">Conectando ...</div>
		<div v-else class="selectedpreview-container d-flex flex-column justify-center align-center text-center pa-2" :style="'background-image: url(' + selectedAvatar.img_preview + ')'">
			<div class="loading-text black--text">{{ended ? 'Sesión Finalizada' : 'Sesión en Espera'}}</div>
			<div class="black--text muted">{{ended ? 'Si deseas puedes volver a llamar presionando el botón verde' : 'Si deseas puedes llamar presionando el botón verde'}}</div>
		</div>
		<div class="bottom-controllers d-flex justify-center pa-6">
			<v-btn v-if="!stream"
				x-large
				color="green"
      	fab
				:loading="isLoadingSession"
				@click="startSession">
				<v-icon
					large
					color="white"
				>
					mdi-phone
				</v-icon>
			</v-btn>
			<v-btn v-if="stream"
				x-large
				color="red"
      	fab
				@click="endSession">
				<v-icon
					large
					color="white"
				>
					mdi-close
				</v-icon>
			</v-btn>
		</div>
	</div>
	<div v-else class="select-avatar-container d-flex justify-center align-center">
		<v-container>
			<v-row>
				<v-col cols="12">
					<p class="font-weight-bold white--text title text-center mb-0">Por favor selecciona con quien prefieres conversar</p>
				</v-col>
				<v-col
					v-for="(avatar, index) in avatarList"
					:key="index"
					cols="12" sm="6" md="3"
				>
					<div
						class="avatar-select-container d-flex flex-column align-center"
						@click="selectAvatar(avatar)">
						<v-img :src="avatar.img_preview" class="avatar-image" />
						<div class="px-4" style="width: 100%;">
							<p class="my-1 text-left font-weight-bold">{{ avatar.name }}</p>
						</div>
					</div>
				</v-col>
			</v-row>

			<v-dialog max-width="800" persistent v-model="dialogTerms" style="z-index: 123;">
        <v-card class="pa-5">
			<h5>Términos de Uso y Política de Privacidad de la Aplicación para Móviles iDUAM</h5>
          <card-text>
            Esta aplicación es operada y desarrollada por NATURALPHONE S.A.
			
			<h6>1. ¿Qué tipo de información podemos solicitar?</h6>
			<p>
				Ocasionalmente y con el objeto de que usted obtenga diversos beneficios por interactuar y mantener activa en su dispositivo móvil nuestra aplicación iDUAM, podremos solicitarle información personal como, por ejemplo, su nombre, nombre de usuario, dirección de correo electrónico, edad.
			</p>
			<h6>2. ¿Qué hacemos con la información que obtenemos?</h6>
			<p>Gracias a los datos que usted nos entregue podremos enviarle información personalizada sobre nuevos productos o servicios de su interés sobre salud en general, innovaciones tecnológicas, de medicamentos, de terapias y tratamientos, o información sobre servicios que a futuro sean incorporados en la aplicación iDUAM. </p>
            <p>Adicionalmente, tanto nuestra aplicación como su sitio web, pueden incluir encuestas opcionales donde se le pregunte por sus opiniones acerca de distintos productos o servicios de salud, como así también, de cómo usted mejoraría nuestra aplicación iDUAM y su sitio web <a target="_blank" href="https://www.iduam.com">https://www.iduam.com</a>.</p>
			<p>La información que obtenemos, la utilizamos de distintos modos:</p>

			<ul>
				<li style="list-style-type: circle;">Para ocuparnos de las solicitudes del Servicio de Atención al Cliente.</li>
				<li style="list-style-type: circle;">Para análisis interno e investigación con el fin de mejorar nuestra aplicación y portal web.</li>
				<li style="list-style-type: circle;">Para enviarle información sobre productos, servicios o beneficios que a usted le podrían interesar.</li>
				<li style="list-style-type: circle;">Para enviarle correos electrónicos administrativos (por ejemplo, si usted olvida su contraseña).</li>
				<li style="list-style-type: circle;">Para prevenir y detectar fraudes o utilización ilegítima de nuestra aplicación y sitio web.</li>
				<li style="list-style-type: circle;">Para realizar encuestas y promociones, o bien, enviar noticias o informaciones que podrían ser de su interés.</li>
			</ul>

            <h6>3. ¿Quién más tiene acceso a su información?</h6>

			<p>Nuestras empresas afiliadas, distribuidores regionales y otros organismos que trabajen con nosotros o que hayan generado acuerdos comerciales con nuestra empresa pueden tener acceso a su información, como por ejemplo, municipios, gobiernos, clínicas, hospitales, isapres, Fonasa o laboratorios, con los cuales podríamos tener convenios de colaboración a futuro para el uso de la aplicación y sitio web iDUAM, con el fin de gestionarla conforme a esos acuerdos y proporcionarle a usted la información y los servicios que haya solicitado, o bien, que le queramos brindar, lo cual usted acepta y brinda consentimiento a efectos de que sus datos personales sean remitidos y/o transferidos a estas empresas u organismos, así como el tratamiento que realicen sobre los mismos.</p>

			<p>Algunas de estas empresas u organismos podrían procesar su información en países que estén fuera de la zona económica europea (EEA), como Estados Unidos y Japón. La legislación de protección de datos de estos países podría no ofrecer el mismo nivel de protección que la de la EEA. Si no desea que transfiramos sus datos de este modo, no acceda, ni utilice nuestro sitio web o aplicación <span style="font-weight: 600;">iDUAM</span>.</p>
        
			<p>Si accede a esta aplicación o la utiliza a través de un sitio web operado por un tercero, este también podrá recabar información sobre usted, lo cual acepta y autoriza.</p>

			<p>En circunstancias excepcionales, podríamos revelar sus datos personales por obligaciones legales, como por ejemplo frente actividades ilegales o perjudiciales.</p>
		
			<p>En caso de venta o reestructuración de nuestro negocio, sus datos podrán transferirse como parte del mismo, lo cual usted acepta y autoriza.</p>
		
			<p>También trabajamos con empresas de terceros para ayudar a mejorar y comprender el uso de nuestra aplicación <span style="font-weight: 600;">iDUAM</span> y su sitio web, para ofrecer así la mejor experiencia posible para usted. Estas
				empresas generan información de uso estadístico y están autorizadas a utilizar su información personal solo para proporcionarnos estos servicios, lo cual usted acepta y autoriza.
			</p>

			<h6>4. Asistente virtual</h6>

			<p><span style="font-weight: 600;">iDUAM</span> ofrece la posibilidad de interactuar con un avatar que se comporta como un asistente virtual. El avatar es un modelo tridimensional de una persona creado digitalmente, lo que permite replicar sus características físicas de manera realista y se comunica con una voz sintética que suena similar a la original, imitando la cadencia, el tono y la modulación de la voz. La información que entrega se encuentra en bases de datos que gracias al uso de Inteligencia Artificial puede ser comunicada a los usuarios a través del avatar en una conversación realista.</p>
			<p>Nuestro asistente virtual actúa como una interfaz que tiene el objetivo único y expreso de facilitar a los usuarios el acceso a la información que soliciten. Bajo ninguna circunstancia debe entenderse como un asesoramiento que entregaría un técnico o profesional. NATURALPHONE S.A. recomienda consultar siempre con un profesional o técnico calificado para responder consultas que requieran ese tipo de asesorías, eximiéndose de cualquier responsabilidad por el mal uso de la información entregada a través del servicio de asistencia virtual por medio de un avatar.</p>
		
			<h6>5. Consentimiento expreso</h6>
			<p>Usted acepta expresamente y de manera íntegra los <span style="font-weight: 600;">Términos de Uso y Política de Privacidad</span> de este texto. Si usted no está de acuerdo en cualquiera de los términos o condiciones expuestos, sencillamente no debe operar la aplicación <span style="font-weight: 600;">iDUAM</span>, ni los servicios que ofrece, como tampoco su sitio web <a href="https://www.iduam.com" target="_blank">https://www.iduam.com</a>.</p>

			<p>NATURALPHONE S.A. se obliga a utilizar los datos recopilados sólo y únicamente para las finalidades permitidas por el ordenamiento jurídico, respetando el pleno ejercicio de los derechos fundamentales de los titulares de los datos y de las facultades que la ley les reconoce.</p>
		
			<p>En caso de cualquier duda o pregunta no dude en contactarnos.</p>
		</card-text>

            <v-spacer></v-spacer>

			<v-checkbox v-model="agreed"  label="Términos de Uso y Política de Privacidad de la Aplicación iDuam"></v-checkbox>
			<div>
            <v-btn
				color="primary"
				:disabled="!agreed"
				@click="closeTermsDialog"
            >Continuar</v-btn>
		</div>
        </v-card>
    </v-dialog>
		</v-container>
	</div>
</template>

<script>
import  StartAvatarResponse  from "@heygen/streaming-avatar"
import StreamingAvatar, { AvatarQuality, StreamingEvents, TaskType, VoiceEmotion } from "@heygen/streaming-avatar"
import { newConversation, newMessageConversation } from '../../components/helpers/api/conversations'

export default {
    components: { StreamingAvatar, AvatarQuality, StreamingEvents, TaskType, VoiceEmotion, StartAvatarResponse },
    data () {
			return {
				tab: 0,
				isLoadingSession: false,
				isLoadingRepeat: false,
				stream: null,
				debug: '',
				knowledgeId: null,
				avatarId: 'ef08039a41354ed5a20565db899373f3',
				language: 'es',
				selectedAvatar: null,
				avatarList: [
					{
						name: 'Carlos',
						img_preview: 'https://files2.heygen.ai/avatar/v3/88d83ee011cc4dc693dad929a6b8acc4/full/2.2/preview_target.webp',
						avatar_id: '88d83ee011cc4dc693dad929a6b8acc4',
						knowledgeId: '69dbd5aa24504bbc8396962005262841',
						voice: {
							voice_id: '7025fdf00a5f47818394e7e60ebfcc83'
						}
					},
					{
						name: 'Sofia',
						img_preview: 'https://files2.heygen.ai/avatar/v3/ef08039a41354ed5a20565db899373f3/full/2.2/preview_target.webp',
						avatar_id: 'ef08039a41354ed5a20565db899373f3',
						knowledgeId: '69dbd5aa24504bbc8396962005262841',
						voice: {
							voice_id: 'ce03fd97e8074a8f935c298c7f68a0df'
						}
					},
					{
						name: 'Juan Carlos',
						img_preview: 'https://files2.heygen.ai/avatar/v3/43c34c4285cb4b6c81856713c70ba23b/full/2.2/preview_target.webp',
						avatar_id: '43c34c4285cb4b6c81856713c70ba23b',
						knowledgeId: '69dbd5aa24504bbc8396962005262841',
						voice: {
							voice_id: 'e3bf80c9e519492ba6482dedaf7829a1'
						}
					},
					{
						name: 'Angélica',
						img_preview: 'https://files2.heygen.ai/avatar/v3/c20f4bdddbe041ecba98d93444f8b29b/full/2.2/preview_target.webp',
						avatar_id: 'c20f4bdddbe041ecba98d93444f8b29b',
						knowledgeId: '69dbd5aa24504bbc8396962005262841',
						voice: {
							voice_id: '95e872563c014ef493aceff42de6f3e9'
						}
					},
					{
						name: 'Eric Jurgensen',
						img_preview: 'https://files2.heygen.ai/avatar/v3/8facbf5854194f348d3956586fc584ae/full/2.2/preview_target.webp',
						avatar_id: '8facbf5854194f348d3956586fc584ae',
						knowledgeId: 'f8fc864059be4dcb88c35d2a327e3062',
						voice: {
							voice_id: 'cbc8c06768fa4551a5642b34e9132b79'
						}
					},
					{
						name: 'Viejo Pascuero',
						img_preview: 'https://files2.heygen.ai/avatar/v3/3b4e464bf15f4194b082be0e631354c6_46860/preview_target.webp',
						avatar_id: 'Santa_Fireplace_Front_public',
						knowledgeId: '5d16210cce684c0ab818ed2f27bc96cd',
						voice: {
							voice_id: null
						}
					},
				],
				data: StartAvatarResponse,
				text: '',
				mediaStream: null,
				avatar: {current: null},
				chatMode: 'text_mode',
				isUserTalking: false,
				sessionData: null,
				messages: [
					{"role": "system", "content": "Eres un asistente de salud de una aplicación llamada Aiduam. Tu género es femenino. En general, da respuestas cortas."},
					{"role": "system", "content": "Puedes entablar una comunicación con el usuario, trata de ser una compañía y guiarlo en temas de salud."},
				],
				socket: null,
				socketUrl: 'https://devapis.anubys.cl:3010',
				playing: false,
				bases: [],
				audioQueue: [],
				audioContext: null,
				text_response: [],
				isAvatarTalking: null,
				audio_avatar_talking: false,
				conversation_id: null,
				last_message: '',
				currentAvatarMessage: '',
				ended: false,
				dialogTerms: false,
				agreed: false,
				dynamicHeight: 0
      }
    },
    methods: {
			selectAvatar(avatar){
				if (!localStorage.getItem('acceptedTerms')) {
					this.selectedAvatar = null;
					this.dialogTerms = true;
					return
				}
				this.selectedAvatar = avatar
				this.startSession()
			},
		async startSession() {
			if (!localStorage.getItem('acceptedTerms')) {
				this.selectedAvatar = null;
				this.dialogTerms = true;
				return
			}
			try {
        this.isLoadingSession = true
				const conversation = await newConversation()
				if(conversation.code == 200){
					this.conversation_id = conversation.result.id
				}
        await this.fetchAccessToken()
        this.avatar.current = new StreamingAvatar({ token: this.token })

				this.avatar.current.on(StreamingEvents.AVATAR_TALKING_MESSAGE, (message) => {
					this.currentAvatarMessage += message.detail.message
				});
				this.avatar.current.on(StreamingEvents.USER_TALKING_MESSAGE, (message) => {
					const data = {conversation_id: this.conversation_id, role: 'user', message: message.detail.message, token: 0}
					newMessageConversation(data)
				});

				this.avatar.current.on(StreamingEvents.AVATAR_START_TALKING, (e) => {
					console.log("Avatar started talking", e);
					this.audio_avatar_talking  = true
				});
				this.avatar.current.on(StreamingEvents.AVATAR_STOP_TALKING, (e) => {
					console.log("Avatar stopped talking", e);
					this.audio_avatar_talking = false
					const data = {conversation_id: this.conversation_id, role: 'assistant', message: this.currentAvatarMessage, token: 0}
					newMessageConversation(data)
					this.currentAvatarMessage = ''
				});
				this.avatar.current.on(StreamingEvents.STREAM_DISCONNECTED, () => {
					console.log("Stream disconnected");
					this.endSession();
					this.stopCamera();
				});
				this.avatar.current.on(StreamingEvents.STREAM_READY, (event) => {
					console.log(">>>>> Stream ready:", event.detail);
					this.stream = event.detail
					this.startCamera();
					setTimeout(() => {
						this.avatar.current.speak({ text: 'Hola. ¿cómo estás?. Soy ' + this.selectedAvatar.name + '. ¿Me podrías indicar cuál es tu nombre?', task_type: TaskType.REPEAT })
					}, 1000);
				});
				this.avatar.current.on(StreamingEvents.USER_START, (event) => {
					console.log(">>>>> User started talking:", event);
					this.avatar.current.interrupt()
					this.isUserTalking = true
				});
				this.avatar.current.on(StreamingEvents.USER_STOP, (event) => {
					console.log(">>>>> User stopped talking:", event);
					this.isUserTalking = false
				});
				this.data = await this.avatar.current.createStartAvatar({
					avatarName: this.selectedAvatar.avatar_id,
					voice: {
						voiceId: this.selectedAvatar.voice.voice_id,
						rate: 1,
						emotion: VoiceEmotion.FRIENDLY,
					},
					knowledgeId: this.selectedAvatar.knowledgeId,
					quality: 'medium',
					language: 'es',  
				})
				
				const voice_chat = await this.avatar.current.startVoiceChat();
			} catch (error) {
				console.error("Error starting avatar session:", error);
			} finally {
				this.isLoadingSession  = false
			}
		},
		async handleSpeak(text) {
			this.isLoadingRepeat = true;
				if (!this.avatar.current) {
					this.debug = "Avatar API not initialized";
					return;
				}

			if (this.chatMode === 'voice_mode') {
				await this.avatar.current.startVoiceChat();
			}

			await this.avatar.current.speak({ text, task_type: TaskType.REPEAT }).catch((e) => {
				this.debug = e.message;
			});

			this.isLoadingRepeat = false;
			this.text = '';
		},
		async endSession() {
			await this.avatar.current.stopAvatar();
			this.stream = undefined;
			this.ended = true;
			this.selectedAvatar = null
		},
		handleChangeChatMode(v) {
			if (v === 'text_mode') {
				this.avatar.current.closeVoiceChat();
			} else {
				this.avatar.current.startVoiceChat();
			}
			this.chatMode = v;
		},
		async fetchAccessToken() {
			try {
				const response = await fetch("https://api.heygen.com/v1/streaming.create_token", {
					method: "POST",
						headers: {
						"x-api-key": this.selectedAvatar.avatar_id == '8facbf5854194f348d3956586fc584ae' ? process.env.VUE_APP_HEYGEN_API_KEY2 : process.env.VUE_APP_HEYGEN_API_KEY,
						"Content-Type": "application/json",
					},
				});
				const res = await response.json()
				const token = await res.data.token

				this.token = token 
				return token;
			} catch (error) {
				console.error("Error fetching access token:", error);
			}
		},
		closeTermsDialog() {
			this.dialogTerms = false;
			localStorage.setItem('acceptedTerms', true)
		},
		async startCamera() {
      try {
        // Solicitar acceso a la cámara
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        
        // Acceder al elemento de video solo después de que esté montado
        const videoElement = this.$refs.video;
        
        // Verificar si la referencia del video es válida
        if (videoElement) {
          videoElement.srcObject = stream; // Asignar el stream al elemento de video
		  this.mediaStream = stream;
        } else {
          console.error("El elemento de video no está disponible.");
        }
      } catch (error) {
        console.error("No se pudo acceder a la cámara", error);
      }
    },
	stopCamera() {
      if (this.mediaStream) {
        // Detener todas las pistas del stream
        const tracks = this.mediaStream.getTracks();
        tracks.forEach(track => track.stop());

        // Desasignar el stream del elemento de video
        const videoElement = this.$refs.video;
        if (videoElement) {
          videoElement.srcObject = null;
        }

        // Limpiar la propiedad mediaStream
        this.mediaStream = null;
      }
    },
	setFullHeight() {
      const vh = window.innerHeight * 0.01;
      this.dynamicHeight = vh * 100;
    }
	},
	watch: {
		stream: {
			immediate: true,
			handler(newStream) {
				if (newStream && this.$refs.mediaStream) {
					this.$refs.mediaStream.srcObject = newStream;
					this.$refs.mediaStream.onloadedmetadata = () => {
						this.$refs.mediaStream.play();
						this.debug = 'Playing';
					};
				}
			},
		},
	},
  onBeforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setFullHeight);
  },
  mounted() {
	this.setFullHeight();
    window.addEventListener('resize', this.setFullHeight);

	//avatar id route query
    const foundAvatar = this.avatarList.find(avatar => avatar.avatar_id === this.$route.query.avatar_id);
    if (foundAvatar) {
		this.selectedAvatar = foundAvatar
    }

	//términos
	if (!localStorage.getItem('acceptedTerms')) {
		this.dialogTerms = true
		this.agreed = true
	}
	},
}
</script>

<style lang="css" scoped>
	.select-avatar-container{
		background: url('/images/fondo-7.svg')  no-repeat;
		background-size: cover;
		background-attachment: fixed;
		width: 100%;
		height: 100%;
	}
	.avatar-select-container{
		border-radius: 10px;
    overflow: hidden;
    background: white;
		border: 1px solid white;
		cursor: pointer;
	}
	/deep/ .avatar-select-container:hover .avatar-image .v-image__image{
		background-size: 120%;
	}
	.avatar-image{
		width: 100%;
		height: 200px;
		overflow: hidden;
	}
	.videocall-container{
		background: url('/images/fondo-7.svg')  no-repeat;
		background-size: cover;
		background-attachment: fixed;
		width: 100%;
		height: 100vh;
		position: relative;
	}
	.selectedpreview-container{
		width: 100%;
		height: 100%;
		position: relative;
		background-position: center;
    background-size: cover;
    background-color: rgba(255,255,255,0.6);
    background-blend-mode: lighten;
	}
	.selectedpreview-container .avatar-image{
		height: 100%;
		object-fit: cover;
	}
	.bottom-controllers{
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
	}
	.loading-text{
		font-size: 25px;
		font-weight: bold;
		color: white;
	}
	.back-button{
		position: absolute;
		top: 10px;
    left: 10px;
    z-index: 999;
	}

	@media (min-width: 1250px) {
		.local-stream {
			max-width: 30%;
			position: absolute;
			bottom: 10%;
			right: 20px;
			max-width: 300px;
		}
	}

	@media (max-width: 1250px) {
	.local-stream {
		width: 100%;
		height: 50vh;
		object-fit: cover;
	}

	.remote-stream {
		height: 50vh;
		object-fit: cover;
	}

	.remote-stream-vertical {
		object-fit: cover;
	}
	}
</style>